import React, { useState, useEffect } from 'react';
import './index.scss';
import { transmitLinkSapi } from '@apis';
export default function SUrl() {
  const [dataObj, setDataObj] = useState({});
  const [isStartRender, setIsStartRender] = useState(false);
  const [isShowSolarCode, setIsShowSolarCode] = useState(false);
  const getTransData = async () => {
    // const terminalVal = localStorage.getItem('terminal');
    try {
      const paramId = window?.page?.body.param_id || '';
      const {
        data = {
          type: '',
          name: '',
          logo: '',
          origin_url: '',
          target_url: '',
          wxmp_qrcode: '',
        },
      } = await transmitLinkSapi({
        data: { param_id: paramId, terminal: 'pc' },
      });

      setDataObj(data);
      setIsStartRender(true);
      if (data.wxmp_qrcode) {
        setIsShowSolarCode(true);
      }
      if (data.target_url && data.type !== 'wxmp') {
        // 自动跳转
        let timerHandle = null;
        if (timerHandle) {
          clearTimeout();
          timerHandle = null;
        }
        timerHandle = setTimeout(
          () => (window.location.href = data.target_url),
          1000
        );
      }
      if (data.name) {
        window.document.title = data.name;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    getTransData();
  }, []);
  const PcMpView = () => {
    return (
      <>
        {isStartRender && (
          <>
            <div className="box-top">
              <div className="top-logo">
                <img src={dataObj.logo} alt="" />
              </div>
              <div className="link-desc">{dataObj.name}</div>
            </div>
            <div className="box-bottom">
              {isShowSolarCode && (
                <>
                  <div className="solar-code">
                    <img src={dataObj.wxmp_qrcode} alt="小程序太阳码" />
                  </div>
                  <div className="solar-code-tip">请使用微信扫一扫打开</div>
                </>
              )}
              {!isShowSolarCode && (
                <>
                  <div className="waiting-message">转入中,请稍等...</div>
                  <div className="link-box">
                    无法打开时,
                    <span
                      className="link-btn"
                      onClick={() =>
                        (window.location.href = dataObj.target_url)
                      }
                    >
                      点此链接
                    </span>
                    直接打开
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <div className="pc-view-box">
      <PcMpView />
    </div>
  );
}
