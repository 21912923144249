import PubSub from 'pubsub-js';
// 发布窗口大小
export const publishSize = () => {
  const BRACK_POINT = 1024;
  const terminals = {
    // 每个接口中有个参数terminal
    pc: 'pc', // pc端
    mobile: 'mob', // 移动端
  };
  const getScreenWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  let device = getScreenWidth() > BRACK_POINT ? 'pc' : 'mobile';

  let terminal = localStorage.getItem('terminal');
  const hasTem = terminal && terminal === terminals[device];
  if (!hasTem) localStorage.setItem('terminal', terminals[device]);

  // 窗口大小改变-->设备类型改变---发布
  const publishSizeChange = () => {
    if (getScreenWidth() > BRACK_POINT && device === 'mobile') {
      device = 'pc';
      localStorage.setItem('terminal', terminals[device]);
      PubSub.publish('resize', device);
    }
    if (getScreenWidth() < BRACK_POINT && device === 'pc') {
      device = 'mobile';
      localStorage.setItem('terminal', terminals[device]);
      PubSub.publish('resize', device);
    }
  };
  // 根组件App初次调用---发布
  PubSub.publish('resize', device);
  // 根组件App初次调用---添加监听
  window.addEventListener('resize', publishSizeChange);
  // 将监听事件函数返回---供取消监听调用
  return publishSizeChange;
};
