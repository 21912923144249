// import { formatQs } from "../utils";
import http from './http';
import { PREFIX, website } from './config';
/**
 *
 * url:接口地址。PREFIX代理时使用，STORE_VIEW（axios）拦截时会替换为window.page.store_view，formatQs将传入的对象{id:123,num:321}转为字符串?id=123&num=321
 * methods: 方法。支持get/post/put/delete
 * Authorization: 接口是否需要token
 * showLoading: 接口是否需要显示Loading效果
 * */

// 转发链接
export const transmitLinkSapi = ({ data = {} }) => {
  return http({
    url: `${PREFIX}/rest/${website}/V1/sapi/track/transformation`,
    methods: 'post',
    data,
    Authorization: false,
    showLoading: false,
  });
};
