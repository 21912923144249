import axios from 'axios';
import { TIMEOUT } from './config';
import { req, reqError, res, resError } from './interceptors';

// axios实例
const axiosInstance = axios.create({
  // baseURL: BASE_URL,
  timeout: TIMEOUT,
});

const http = function ({
  url = '',
  methods = 'get',
  data = {},
  header = {},
  Authorization = false,
  showLoading = false,
}) {
  switch (methods) {
    case 'get':
      return axiosInstance.get(url, {
        headers: header,
        showLoading,
        Authorization,
      });
    case 'post':
      return axiosInstance.post(url, data, {
        headers: header,
        showLoading,
        Authorization,
      });
    case 'put':
      return axiosInstance.put(url, data, { showLoading, Authorization });
    case 'delete':
      return axiosInstance.delete(url, { showLoading, Authorization });
    default:
      return new Promise((resolve, reject) =>
        reject(`该接口${url} 方法'${methods}'不存在！！！`)
      );
  }
};

// 请求拦截
axiosInstance.interceptors.request.use(req, reqError);

// 响应拦截
axiosInstance.interceptors.response.use(res, resError);
export default http;
