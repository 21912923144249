import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import './index.scss';
import { transmitLinkSapi } from '@apis';
export default function SUrl() {
  const [isStartRender, setIsStartRender] = useState(false);
  const [dataObj, setDataObj] = useState({});
  const [isShowWXBtn, setIsShowWXBtn] = useState(false);
  const getTransData = async () => {
    // const terminalVal = localStorage.getItem('terminal');
    const paramId = window?.page?.body.param_id || '';
    try {
      const {
        data = {
          type: '',
          name: '',
          logo: '',
          origin_url: '',
          target_url: '',
          wxmp_qrcode: '',
        },
      } = await transmitLinkSapi({
        data: { param_id: paramId, terminal: 'mob' },
      });

      setDataObj(data);
      setIsStartRender(true);
      if (data.type === 'wxmp') {
        // 微信小程序短连接
        setIsShowWXBtn(true);
      } else {
        // 网站短连接
        setIsShowWXBtn(false);
      }
      if (data.target_url) {
        // 自动跳转
        let timerHandle = null;
        if (timerHandle) {
          clearTimeout();
          timerHandle = null;
        }
        timerHandle = setTimeout(
          () => (window.location.href = data.target_url),
          1000
        );
      }
      if (data.name) {
        window.document.title = data.name;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    getTransData();
  }, []);
  const handleCopyLink = (linkVal) => {
    if (linkVal && navigator.clipboard) {
      navigator.clipboard
        .writeText(linkVal)
        .then(() => {
          message.success('复制成功');
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      alert('剪切板被禁用');
    }
  };
  return (
    <>
      {isStartRender && (
        <div className="mob-view-box">
          <div className="view-box-top">
            <div className="top-logo">
              <img src={dataObj.logo} alt="" />
            </div>
            <div className="link-desc">{dataObj.name}</div>
          </div>
          <div className="view-box-bottom">
            {isShowWXBtn && (
              <div
                className="wx-btn-box"
                onClick={() => (window.location.href = dataObj.target_url)}
              >
                <span className="wx-btn">从微信内打开</span>
              </div>
            )}
            {!isShowWXBtn && (
              <div className="waiting-message">转入中,请稍等...</div>
            )}
            <div className="link-box">
              无法打开时,可使用浏览器打开
              <span
                className="link-btn"
                onClick={() => handleCopyLink(dataObj.target_url)}
              >
                复制链接
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
