const page_base_url = window?.page?.baseURL || '';
const page_base_url_ows = window?.page?.ows?.baseUrl || '';

function getBaseUrl() {
  if (process.env.NODE_ENV === 'development') {
    return {
      BASE_URL: '',
      PREFIX: '/api',
      OWHPREFIX: '/owhapi',
    };
  } else {
    return {
      BASE_URL: '',
      PREFIX: page_base_url,
      OWHPREFIX: page_base_url_ows,
    };
  }
}
const { BASE_URL, OWHPREFIX, PREFIX } = getBaseUrl();

export const website = window?.page?.store_view || 'zh_cn';
export const TIMEOUT = 60000;
export { BASE_URL, OWHPREFIX, PREFIX };
