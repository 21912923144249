import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { message, Spin } from 'antd';

const CancelToken = axios.CancelToken;
// 进行中的请求列表
let pendingReqs = [];
let requestCount = 0;
// 打开loading
function startLoading() {
  if (requestCount === 0) {
    // 解决一个页面多个接口请求需要loading
    var dom = document.createElement('div');
    dom.setAttribute('id', 'loading');
    document.body.appendChild(dom);
    const loadingRoot = ReactDOM.createRoot(dom);
    loadingRoot.render(<Spin tip="拼命加载中..." size="small" />);
  }
  requestCount++;
}

// 隐藏loading
const hideLoading = () => {
  requestCount--;
  if (requestCount === 0) {
    document.body.removeChild(document.getElementById('loading'));
  }
};

// 请求拦截--request
export const req = (config) => {
  if (config.headers.ContentType) {
    config.headers['Content-Type'] = config.headers.ContentType;
  } else {
    config.headers['Content-Type'] = 'application/json';
  }
  if (config.headers.XRequestedWith) {
    config.headers['X-Requested-With'] = config.headers.XRequestedWith;
  }
  if (config.showLoading) {
    startLoading();
  }
  pendingReqs.push({ apiUrl: config.url });
  // 取消请求
  let cancelReq = null;
  config.cancelToken = new CancelToken((c) => {
    cancelReq = c;
  });
  return config;
};
// 请求拦截-error
export const reqError = (err) => {
  message.destroy();
  return Promise.reject(err);
};
// 响应成功处理
export const res = (res) => {
  hideLoading();
  const requestIndex = pendingReqs.findIndex(
    (item) => item.apiUrl === res.config.url
  );
  pendingReqs.splice(requestIndex, 1);
  const data = res.data;

  switch (data.code) {
    case 200:
      return data;

    default:
      return Promise.reject(data);
  }
};
// 响应失败处理
export const resError = (err) => {
  return Promise.reject(err);
};
