import { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';

export default function useTeminal() {
  const [teminalType, setTeminalType] = useState(() => {
    const BRACK_POINT = 1024;
    //   惰性函数初始化--获取终端类型
    const getScreenWidth = () =>
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    let device = getScreenWidth() > BRACK_POINT ? 'pc' : 'mobile';
    // if (device === 'pc') {
    //   window.document.documentElement.style.fontSize = '72px';
    // } else {
    //   window.document.documentElement.style.fontSize = '19.2vw';
    // }
    return device;
  });
  const mySubscribeFunc = (_, type) => {
    setTeminalType(type);
  };

  useEffect(() => {
    const PubSub_Sub_Func = PubSub.subscribe('resize', mySubscribeFunc);
    return () => {
      PubSub.unsubscribe(PubSub_Sub_Func);
    };
  }, []);

  return teminalType;
}
