import { useEffect } from 'react';
import { publishSize } from './utils/pubsub';
import useTeminal from './hooks/useTeminal';
import * as PcViews from '@/views/PcViews';
import * as MobViews from '@/views/MobileViews';
import './App.scss';
function App() {
  let teminalTypeValue = useTeminal();
  useEffect(() => {
    const resizeFunc = publishSize();
    return () => {
      window.removeEventListener('resize', resizeFunc);
    };
  }, []);
  let DynamicView;
  let content_id = window?.page?.body?.content_id || 'surl';
  if (teminalTypeValue === 'pc') {
    DynamicView = PcViews[content_id];
  } else {
    DynamicView = MobViews[content_id];
  }
  return (
    <div className="App">
      <DynamicView />
    </div>
  );
}

export default App;
